/*
 * Name: 404
 * Desc:
 *
*/

@import "@styles/variables.scss";

.NotFound {
  background: $grey-background;
  .height {
    min-height: calc(100vh - 95px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .inner {
    text-align: center;
    h1 {
      font-weight: 600;
      font-size: 120px;
      line-height: 0.5;
      @media screen and (min-width: $tablet) {
        font-size: 200px;
      }
    }
    h3 {
        display: block;
      margin-top: $base-1;
    }
    img {
      margin-bottom: $base-1;
      max-width: 80px;
      @media screen and (min-width: $tablet) {
        max-width: 100%;
      }
    }
    a {
      font-weight: 600;
      border-bottom: 1px solid;
      padding-bottom: 2px;
      font-size: 15px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
